import axios from 'axios';
import Cookies from 'js-cookie'
// import router from '../router/index'
// import store from '../store/index.js'

const http = axios.create({
    // baseURL: "https://test2.jdcloud.vip/",
    baseURL: "https://trade.healthplatform.xyz/",
    timeout: 10000
});

http.interceptors.request.use(config => {
    let token = ''
        // let role = Cookies.get('milk_role');
        // if(role == 'admin'){
        //   token = Cookies.get('milk_admin_token')
        // }else if(role == 'seller'){
        //   token = Cookies.get('milk_seller_token')
        // }else{
        //   token = Cookies.get('milk_user_token')
        // }
        // this.token = wx.getStorageSync("token")
    token = Cookies.get('token');
    console.log(token, "token")
    if (token) {
        config.headers['Authorization'] = token;
    } else {
        // window.location.href = store.state.URL +"lador/#/index/index"       
        // window.location.href = " https://trade.healthplatform.xyz/lador/#/index/index"       
    }
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})
http.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    console.log(error);
    return Promise.reject(error);
});

export default http;